import { createSlice } from "@reduxjs/toolkit";

export const machineSlice = createSlice({
  name: "machine",
  initialState: {
    dialog_open: false,
    doc_id: null,
  },
  reducers: {
    toggleDialog: (state, action) => ({
      ...state,
      dialog_open: !state.dialog_open,
      doc_id: action.payload,
    }),
  },
});

export const { toggleDialog } = machineSlice.actions;

export default machineSlice.reducer;

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Form
import { Controller } from "react-hook-form";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";

const SelectController = forwardRef(
  ({ name, label, control, rules, defaultValue, children, ...props }, ref) => (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => <MDInput {...field} {...props} {...ref} label={label} />}
    />
  )
);

// Setting default values for the props of MDInput
SelectController.defaultProps = {
  name: null,
  label: null,
  rules: null,
  control: null,
  defaultValue: null,
  children: null,
};

// Typechecking props for the MDInput
SelectController.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.instanceOf(Object),
  rules: PropTypes.instanceOf(Object),
  defaultValue: PropTypes.string,
  children: PropTypes.node,
};

export default SelectController;

import { forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Form
import { Controller, useForm } from "react-hook-form";

// firebase
import { useFirestore } from "react-redux-firebase";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";

// @mui material icon components
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";

// Redux
import { toggleDialog } from "redux/product";

// Validation
import ProductValidate from "validations/product_setup/ProductValidate";

// Firebase
import { auth } from "../../../../../firebase";

const Transition = forwardRef(({ ...rest }, ref) => <Slide direction="up" ref={ref} {...rest} />);

function Form() {
  const firestore = useFirestore();
  const dispatch = useDispatch();

  // Selector
  const profile = useSelector((state) => state.firebase.profile);
  const category = useSelector((state) => state.firestore.ordered.category);
  const productUnit = useSelector((state) => state.firestore.ordered.product_unit);
  const open = useSelector((state) => state.product.dialog_open);
  const docId = useSelector((state) => state.product.doc_id);
  const product = useSelector((state) => (docId ? state.firestore.data.product[docId] : null));

  // Hook Form
  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur",
  });

  // Effect
  useEffect(() => {
    if (product) {
      setValue("name", product.name);
      if (product.category) {
        if (product.category.doc_id !== undefined) {
          setValue("category", product.category.doc_id);
        }
      }
      if (product.uom) {
        if (product.uom.doc_id !== undefined) {
          setValue("uom", product.uom.doc_id);
        }
      }
      setValue("sku", product.sku);
      setValue("vendlifeId", product.vendlife_id ? product.vendlife_id : "");
    }
  }, [product, setValue]);

  // Functions
  function makeId() {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 6) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const handleOnAutoGenerate = () => {
    setValue("sku", makeId());
  };

  const handleOnClose = () => {
    reset();
    dispatch(toggleDialog());
  };

  const onSubmit = async (value) => {
    const categoryData = category.filter((e) => e.id === value.category);
    const unitData = productUnit.filter((e) => e.id === value.uom);

    if (docId) {
      const doc = firestore.collection("product").doc(docId);
      await doc.update({
        name: value.name,
        sku: value.sku,
        category: {
          doc_id: categoryData[0].id,
          name: categoryData[0].name,
        },
        uom: {
          doc_id: unitData[0].id,
          name: unitData[0].name,
        },
        vendlife_id: value.vendlifeId ? parseInt(value.vendlifeId, 10) : "",
        updated_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
        },
        created_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
        },
        status: "active",
      });
    } else {
      const doc = firestore.collection("product").doc();
      await doc.set({
        name: value.name,
        sku: value.sku,
        category: {
          doc_id: categoryData[0].id,
          name: categoryData[0].name,
        },
        uom: {
          doc_id: unitData[0].id,
          name: unitData[0].name,
        },
        vendlife_id: value.vendlifeId ? parseInt(value.vendlifeId, 10) : "",
        created_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
        },
        status: "active",
      });
    }

    handleOnClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={handleOnClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }} color="info">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleOnClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" color="white" component="div">
            {product ? "Edit Product" : "Create Product"}
          </MDTypography>
          <MDButton variant="text" onClick={handleSubmit(onSubmit)}>
            <Icon>save</Icon>&nbsp;save
          </MDButton>
        </Toolbar>
      </AppBar>
      <MDBox pb={3} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} px={3} py={3}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={12}>
                <MDBox>
                  <Controller
                    control={control}
                    name="name"
                    {...register("name", ProductValidate.name.rules)}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        label="Name"
                        error={!!errors.name}
                        helperText={ProductValidate.name?.message[errors.name?.type]}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} lg={6}>
                <MDBox>
                  <Controller
                    control={control}
                    name="sku"
                    {...register("sku", ProductValidate.sku.rules)}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        label="SKU"
                        error={!!errors.sku}
                        helperText={ProductValidate.sku?.message[errors.sku?.type]}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} lg={6}>
                <MDBox>
                  <MDButton variant="text" color="info" onClick={handleOnAutoGenerate}>
                    Auto Generate
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}>
                <MDBox>
                  <Controller
                    control={control}
                    name="category"
                    {...register("category", ProductValidate.category.rules)}
                    render={({ field }) => (
                      <MDSelect
                        {...field}
                        required
                        name="category"
                        size="large"
                        label="Category"
                        InputProps={{
                          classes: { root: "select-input-styles" },
                        }}
                        fullWidth
                        error={!!errors.category}
                        helperText={ProductValidate.category?.message[errors.category?.type]}
                      >
                        {category ? (
                          category.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Options</MenuItem>
                        )}
                      </MDSelect>
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} lg={6}>
                <MDBox>
                  <Controller
                    control={control}
                    name="uom"
                    {...register("uom", ProductValidate.uom.rules)}
                    render={({ field }) => (
                      <MDSelect
                        {...field}
                        required
                        name="uom"
                        size="large"
                        label="UOM"
                        InputProps={{
                          classes: { root: "select-input-styles" },
                        }}
                        fullWidth
                        error={!!errors.uom}
                        helperText={ProductValidate.uom?.message[errors.uom?.type]}
                      >
                        {productUnit ? (
                          productUnit.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Options</MenuItem>
                        )}
                      </MDSelect>
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} lg={6}>
                <MDBox>
                  <Controller
                    control={control}
                    name="vendlifeId"
                    {...register("vendlifeId")}
                    render={({ field }) => <MDInput {...field} fullWidth label="Vendlife ID" />}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

export default Form;

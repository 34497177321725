import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDModalRoot from "components/MDModal/MDModalRoot";

const MDModal = forwardRef(({ children, ...rest }, ref) => (
  <MDModalRoot {...rest} ref={ref}>
    <div>{children}</div>
  </MDModalRoot>
));

// Typechecking props for the MDInput
MDModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MDModal;

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDSelectRoot from "components/MDSelect/MDSelectRoot";

const MDSelect = forwardRef(({ error, success, disabled, children, ...rest }, ref) => (
  <MDSelectRoot select {...rest} ref={ref} ownerState={{ error, success, disabled }}>
    {children}
  </MDSelectRoot>
));

// Setting default values for the props of MDInput
MDSelect.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDSelect.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDSelect;

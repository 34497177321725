import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Form
import { useForm } from "react-hook-form";

// firebase
import { useFirestore } from "react-redux-firebase";

// Redux
import { toggleDialog } from "redux/cash_received";

// Validation
import Validation from "validations/cash_received";

// Form Controller
import InputController from "components/InputController";
import SelectController from "components/SelectController";

// Libraries
import moment from "moment";

// Firebase
import { auth } from "../../../../../firebase";

export default function FormDialog() {
  const firestore = useFirestore();
  const dispatch = useDispatch();

  // Selector
  const profile = useSelector((state) => state.firebase.profile);
  const open = useSelector((state) => state.cash_received.dialog_open);
  const machines = useSelector((state) => state.firestore.ordered.machine);
  const docId = useSelector((state) => state.cash_received.doc_id);
  const cashReceived = useSelector((state) =>
    docId ? state.firestore.data.cash_received[docId] : null
  );

  const status = ["pending", "completed"];
  const defaultAmountValue = { coin1: 0.1, coin2: 0.2, coin5: 0.5, note1: 1, note5: 5, note10: 10 };
  const [coin1Amount, setCoin1Amount] = useState(0);
  const [coin2Amount, setCoin2Amount] = useState(0);
  const [coin5Amount, setCoin5Amount] = useState(0);
  const [note1Amount, setNote1Amount] = useState(0);
  const [note5Amount, setNote5Amount] = useState(0);
  const [note10Amount, setNote10Amount] = useState(0);

  // Hook Form
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  // Watch
  const [coin1, coin2, coin5, note1, note5, note10] = watch([
    "coin1",
    "coin2",
    "coin5",
    "note1",
    "note5",
    "note10",
  ]);

  useEffect(() => {
    if (cashReceived) {
      setValue("machine", cashReceived.machine.doc_id);
      setValue("status", cashReceived.status);
      setValue("total", cashReceived.total.toFixed(2));
      setValue(
        "coin1",
        cashReceived.coin_1 && cashReceived.coin_1.pcs > 0 ? cashReceived.coin_1.pcs.toString() : ""
      );
      setValue(
        "coin2",
        cashReceived.coin_2 && cashReceived.coin_2.pcs > 0 ? cashReceived.coin_2.pcs.toString() : ""
      );
      setValue(
        "coin5",
        cashReceived.coin_5 && cashReceived.coin_5.pcs > 0 ? cashReceived.coin_5.pcs.toString() : ""
      );
      setValue(
        "note1",
        cashReceived.note_1 && cashReceived.note_1.pcs > 0 ? cashReceived.note_1.pcs.toString() : ""
      );
      setValue(
        "note5",
        cashReceived.note_5 && cashReceived.note_5.pcs > 0 ? cashReceived.note_5.pcs.toString() : ""
      );
      setValue(
        "note10",
        cashReceived.note_10 && cashReceived.note_10.pcs > 0
          ? cashReceived.note_10.pcs.toString()
          : ""
      );
      setValue(
        "date",
        moment(new Date(cashReceived.received_date.seconds * 1000)).format("YYYY-MM-DD")
      );
      setValue("time", moment(new Date(cashReceived.received_date.seconds * 1000)).format("HH:mm"));
      if (cashReceived.bank_in_date) {
        setValue(
          "bank_in_date",
          moment(new Date(cashReceived.bank_in_date.seconds * 1000)).format("YYYY-MM-DD")
        );
      }
    } else {
      setValue("total", "0.00");
      setValue("coin1", "");
      setValue("coin2", "");
      setValue("coin5", "");
      setValue("note1", "");
      setValue("note5", "");
      setValue("note10", "");
    }
  }, [cashReceived, setValue]);

  useEffect(() => {
    if (coin1 !== undefined) {
      setCoin1Amount(coin1 * defaultAmountValue.coin1);
    }
  }, [coin1, setValue]);

  useEffect(() => {
    if (coin2 !== undefined) {
      setCoin2Amount(coin2 * defaultAmountValue.coin2);
    }
  }, [coin2, setValue]);

  useEffect(() => {
    if (coin5 !== undefined) {
      setCoin5Amount(coin5 * defaultAmountValue.coin5);
    }
  }, [coin5, setValue]);

  useEffect(() => {
    if (note1 !== undefined) {
      setNote1Amount(note1 * defaultAmountValue.note1);
    }
  }, [note1, setValue]);

  useEffect(() => {
    if (note5 !== undefined) {
      setNote5Amount(note5 * defaultAmountValue.note5);
    }
  }, [note5, setValue]);

  useEffect(() => {
    if (note10 !== undefined) {
      setNote10Amount(note10 * defaultAmountValue.note10);
    }
  }, [note10, setValue]);

  // Functions
  const handleClose = () => {
    dispatch(toggleDialog());
    reset({
      machine: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
      time: moment(new Date()).format("HH:mm"),
      status: "pending",
      bank_in_date: "",
    });
  };

  const onSubmit = async (value) => {
    const machineData = machines.filter((e) => e.id === value.machine);
    const date = moment(`${value.date} ${value.time}:00`, "YYYY-MM-DD HH:mm:ss");
    const bankInDate = moment(`${value.bank_in_date}`, "YYYY-MM-DD HH:mm:ss");

    const totalNote1 = parseInt(value.note1 * 1, 10);
    const totalNote5 = parseInt(value.note5 * 5, 10);
    const totalNote10 = parseInt(value.note10 * 10, 10);

    if (docId) {
      const doc = firestore.collection("cash_received").doc(docId);
      await doc.update({
        machine: {
          doc_id: machineData[0].id,
          name: machineData[0].name,
        },
        updated_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
          date: new Date(),
        },
        received_date: date.toDate("LLL"),
        bank_in_date: bankInDate.toDate("LLL"),
        coin_1: {
          pcs: parseInt(value.coin1, 10),
          amount: coin1Amount,
        },
        coin_2: {
          pcs: parseInt(value.coin2, 10),
          amount: coin2Amount,
        },
        coin_5: {
          pcs: parseInt(value.coin5, 10),
          amount: coin5Amount,
        },
        note_1: {
          pcs: parseInt(value.note1, 10),
          amount: totalNote1,
        },
        note_5: {
          pcs: parseInt(value.note5, 10),
          amount: totalNote5,
        },
        note_10: {
          pcs: parseInt(value.note10, 10),
          amount: totalNote10,
        },
        total: coin1Amount + coin2Amount + coin5Amount + note1Amount + note5Amount + note10Amount,
        status: value.status,
      });
    } else {
      const doc = firestore.collection("cash_received").doc();
      await doc.set({
        machine: {
          doc_id: machineData[0].id,
          name: machineData[0].name,
        },
        received_date: date.toDate("LLL"),
        created_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
          date: new Date(),
        },
        coin_1: {
          pcs: value.coin1 !== "" ? parseInt(value.coin1, 10) : 0,
          amount: value.coin1 !== "" ? coin1Amount : 0,
        },
        coin_2: {
          pcs: parseInt(value.coin2, 10),
          amount: 0,
        },
        note_1: {
          pcs: parseInt(value.note1, 10),
          amount: totalNote1,
        },
        note_5: {
          pcs: parseInt(value.note5, 10),
          amount: totalNote5,
        },
        note_10: {
          pcs: parseInt(value.note10, 10),
          amount: totalNote10,
        },
        total: totalNote1 + totalNote5 + totalNote10,
        status: "pending",
      });
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" scroll="body">
      <DialogTitle>{cashReceived ? "Edit Cash Received" : "New Cash Received"}</DialogTitle>
      <DialogContent>
        <MDBox lg={12} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} py={1}>
            <Grid item lg={4}>
              <SelectController
                fullWidth
                control={control}
                rules={Validation.machine.rules}
                label="Machine"
                name="machine"
                defaultValue={cashReceived ? cashReceived.machine.doc_id : ""}
                error={!!errors.machine}
                helperText={Validation.machine?.message[errors.machine?.type]}
              >
                {machines ? (
                  machines.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="no-option" value="">
                    No Options
                  </MenuItem>
                )}
              </SelectController>
            </Grid>
            <Grid item lg={4}>
              <InputController
                fullWidth
                required
                control={control}
                rules={Validation.date.rules}
                label="Received Date"
                name="date"
                type="date"
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                error={!!errors.date}
                helperText={Validation.date?.message[errors.date?.type]}
              />
            </Grid>
            <Grid item lg={4}>
              <InputController
                fullWidth
                required
                control={control}
                rules={Validation.time.rules}
                label="Received Time"
                name="time"
                type="time"
                defaultValue={moment(new Date()).format("HH:mm")}
                error={!!errors.time}
                helperText={Validation.time?.message[errors.time?.type]}
              />
            </Grid>
            <Grid item lg={4}>
              <SelectController
                fullWidth
                control={control}
                label="Status"
                name="status"
                defaultValue={cashReceived ? cashReceived.status : "pending"}
              >
                {status ? (
                  status.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="no-option" value="">
                    No Options
                  </MenuItem>
                )}
              </SelectController>
            </Grid>
            <Grid item lg={4}>
              <InputController
                fullWidth
                required
                control={control}
                rules={Validation.bank_in_date?.rules}
                label="Bank In Date"
                name="bank_in_date"
                type="date"
                error={!!errors.bank_in_date}
                // helperText={Validation.bank_in_date?.message[errors?.bank_in_date?.type]}
              />
            </Grid>
            <Grid item lg={4}>
              <InputController
                fullWidth
                control={control}
                label="Total"
                name="total"
                type="number"
                value={(
                  coin1Amount +
                  coin2Amount +
                  coin5Amount +
                  note1Amount +
                  note5Amount +
                  note10Amount
                ).toFixed(2)}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} py={1} pt={2}>
            <Grid item lg={4}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item lg={4}>
                  <MDTypography>RM0.10</MDTypography>
                </Grid>
                <Grid item lg={3}>
                  <InputController
                    fullWidth
                    required
                    control={control}
                    name="coin1"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <MDTypography px={1}>pcs</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item lg={4}>
                  <MDTypography>RM0.20</MDTypography>
                </Grid>
                <Grid item lg={3}>
                  <InputController
                    fullWidth
                    required
                    control={control}
                    name="coin2"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item lg={1}>
                  <MDTypography px={1}>pcs</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item lg={4}>
                  <MDTypography>RM0.50</MDTypography>
                </Grid>
                <Grid item lg={3}>
                  <InputController
                    fullWidth
                    required
                    control={control}
                    name="coin5"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item lg={1}>
                  <MDTypography px={1}>pcs</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item lg={4}>
                  <MDTypography>RM1</MDTypography>
                </Grid>
                <Grid item lg={3}>
                  <InputController
                    fullWidth
                    required
                    control={control}
                    name="note1"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item lg={1}>
                  <MDTypography px={1}>pcs</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item lg={4}>
                  <MDTypography>RM5</MDTypography>
                </Grid>
                <Grid item lg={3}>
                  <InputController
                    fullWidth
                    required
                    control={control}
                    // rules={Validation.date.rules}
                    name="note5"
                    type="number"
                  />
                </Grid>
                <Grid item lg={1}>
                  <MDTypography px={1}>pcs</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item lg={4}>
                  <MDTypography>RM10</MDTypography>
                </Grid>
                <Grid item lg={3}>
                  <InputController
                    fullWidth
                    required
                    control={control}
                    // rules={Validation.date.rules}
                    name="note10"
                    type="number"
                  />
                </Grid>
                <Grid item lg={1}>
                  <MDTypography px={1}>pcs</MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSubmit)}>{cashReceived ? "Update" : "Create"}</Button>
      </DialogActions>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

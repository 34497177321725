// @mui material components
// import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

export default styled(Autocomplete)(({ theme, ownerState }) => {
  const { palette } = theme;
  const { error } = ownerState;

  const { error: colorError } = palette;
  // const { pxToRem } = functions;

  // styles for the input with error={true}
  const errorStyles = () => ({
    // backgroundImage:
    //   "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44335' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44335' stroke='none'/%3E%3C/svg%3E\")",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: `right ${pxToRem(12)} center`,
    // backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,

    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: colorError.main,
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: colorError.main,
    },
  });

  return {
    pointerEvents: "auto",
    ...(error && errorStyles()),
  };
});

import { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Form
import { Controller, useForm, useFieldArray } from "react-hook-form";

// firebase
import { useFirestore } from "react-redux-firebase";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";

// @mui material icon components
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";

// Components
import ProductFormModal from "layouts/stock/received/components/ProductFormModal";

// Redux
import { toggleDialog } from "redux/stock_received";

// Libraries
import moment from "moment";

// Firebase
import { auth } from "../../../../../firebase";

const Transition = forwardRef(({ ...rest }, ref) => <Slide direction="up" ref={ref} {...rest} />);

const ActionButton = forwardRef(({ ...rest }, ref) => {
  // const { stockReceived, onDraftClick, onConfirmClick } = rest;
  const { stockReceived, onDraftClick } = rest;

  if (stockReceived && stockReceived.status === "draft") {
    return (
      <>
        <MDButton variant="text" onClick={onDraftClick} ref={ref}>
          <Icon>save</Icon>&nbsp;save as draft
        </MDButton>
        {/* <MDButton variant="text" onClick={onConfirmClick} ref={ref}>
          <Icon>done</Icon>&nbsp;confirm
        </MDButton> */}
      </>
    );
  }

  return (
    <MDButton variant="text" onClick={onDraftClick} ref={ref}>
      <Icon>save</Icon>&nbsp;save
    </MDButton>
  );
});

function NewFormDialog() {
  const firestore = useFirestore();
  const dispatch = useDispatch();

  // Selector
  const supplier = useSelector((state) => state.firestore.ordered.supplier);
  const open = useSelector((state) => state.stock_received.dialog_open);
  const profile = useSelector((state) => state.firebase.profile);
  const docId = useSelector((state) => state.stock_received.doc_id);
  const stockReceived = useSelector((state) =>
    docId ? state.firestore.data.stock_received[docId] : null
  );

  // Hook Form
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isDirty, isSubmitting },
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
    rules: {
      required: true,
    },
  });

  const [removeIndex, setRemoveIndex] = useState(-1);
  const [openProductModal, setProductModalOpen] = useState(false);
  const handleProductModalOpen = () => setProductModalOpen(true);
  const handleProductModalClose = () => setProductModalOpen(false);

  useEffect(() => {
    if (!isSubmitting) {
      if (stockReceived) {
        setValue("supplier", stockReceived.supplier.id);
        setValue("invoiceNo", stockReceived.invoice_no);
        setValue("date", stockReceived.invoice_date);

        stockReceived.items?.map((item) =>
          append({
            itemId: item.id,
            itemName: item.name,
            costPrice: parseFloat(item.cost_price).toFixed(2),
            qty: item.qty,
            unitCostPrice: parseFloat(item.unit_cost_price).toFixed(2),
          })
        );
      } else {
        setValue("date", moment(new Date()).format("YYYY-MM-DD"));
        setValue("items", []);
      }
    }
  }, [stockReceived, setValue, append]);

  useEffect(() => {
    remove(removeIndex);
  }, [removeIndex, remove]);

  const handleCostPriceChange = (index, event) => {
    const items = getValues("items");
    const unitCostPrice = parseFloat(event.target.value / items[index].qty).toFixed(2);

    setValue(`items[${index}]`, {
      itemId: items[index].itemId,
      itemName: items[index].itemName,
      costPrice: event.target.value,
      qty: items[index].qty,
      unitCostPrice,
    });
  };

  const handleQtyChange = (index, event) => {
    const items = getValues("items");
    const unitCostPrice = parseFloat(items[index].costPrice / event.target.value).toFixed(2);

    setValue(`items[${index}]`, {
      itemId: items[index].itemId,
      itemName: items[index].itemName,
      costPrice: items[index].costPrice,
      qty: event.target.value,
      unitCostPrice,
    });
  };

  const handleAddItem = (value) => {
    if (value.item.name !== "") {
      append({
        itemId: value.item.id,
        itemName: value.item.name,
        costPrice: value.costPrice !== "" ? parseFloat(value.costPrice).toFixed(2) : 0,
        qty: value.qty,
        unitCostPrice:
          value.costPrice !== "" && value.qty !== ""
            ? parseFloat(value.costPrice / value.qty).toFixed(2)
            : 0,
      });
    }
  };

  const handleOnClose = () => {
    reset({
      date: moment(new Date()).format("YYYY-MM-DD"),
      items: [],
    });
    setRemoveIndex(-1);
    dispatch(toggleDialog());
  };

  const onSubmit = async (value, status) => {
    const supplierData = supplier.filter((e) => e.id === value.supplier);
    const newItemList = value.items.map((e) => ({
      id: e.itemId,
      name: e.itemName,
      cost_price: parseFloat(e.costPrice),
      unit_cost_price: parseFloat(e.unitCostPrice),
      qty: parseInt(e.qty, 10),
    }));
    if (docId) {
      const doc = firestore.collection("stock_received").doc(docId);
      await doc.update({
        invoice_date: value.date,
        invoice_no: value.invoiceNo,
        items: newItemList,
        supplier: {
          id: supplierData[0].id,
          name: supplierData[0].name,
        },
        total_amount: value.items.map((item) => parseFloat(item.costPrice)).reduce((a, b) => a + b),
        updated_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
          date: new Date(),
        },
        status,
      });
    } else {
      const doc = firestore.collection("stock_received").doc();
      await doc.set({
        invoice_date: value.date,
        invoice_no: value.invoiceNo,
        items: newItemList,
        supplier: {
          id: supplierData[0].id,
          name: supplierData[0].name,
        },
        total_amount: value.items.map((item) => parseFloat(item.costPrice)).reduce((a, b) => a + b),
        created_info: {
          doc_id: auth.currentUser.uid,
          email: profile.email,
          display_name: profile.display_name,
          date: new Date(),
        },
        status,
      });
    }

    handleOnClose();
  };

  const onDraftSubmit = async (value) => {
    onSubmit(value, "draft");
  };

  // const onConfirmSubmit = async (value) => {
  //   onSubmit(value, "confirm");
  // };

  const validate = {
    supplier: {
      rules: {
        required: true,
      },
      message: {
        required: "Supplier is required",
      },
    },
    invoiceNo: {
      rules: {
        required: true,
      },
      message: {
        required: "Invoice No. is required",
      },
    },
    items: {
      costPrice: {
        message: {
          required: "Cost Price is required",
        },
      },
      qty: {
        rules: { required: true },
        message: {
          required: "Qty is required",
        },
      },
      unitCostPrice: {
        rules: { required: true },
        message: {
          required: "Unit Cost Price is required",
        },
      },
      message: {
        required: "Minimum ONE item is required.",
        minLength: "Minimum ONE item is required.",
      },
    },
  };

  return (
    <Dialog fullScreen open={open} onClose={handleOnClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }} color="info">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleOnClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" color="white" component="div">
            {stockReceived ? "Edit Stock Received" : "Create Stock Received"}
          </MDTypography>
          <ActionButton
            stockReceived={stockReceived}
            onDraftClick={handleSubmit(onDraftSubmit)}
            // onConfirmClick={handleSubmit(onConfirmSubmit)}
          />
        </Toolbar>
      </AppBar>
      <MDBox pb={3} component="form" noValidate>
        <Grid container spacing={6}>
          <Grid item xs={12} mx={2} my={2} py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <MDBox>
                  <Controller
                    control={control}
                    name="supplier"
                    defaultValue={stockReceived?.supplier ? stockReceived.supplier.id : ""}
                    {...register("supplier", { required: true })}
                    render={({ field }) => (
                      <MDSelect
                        {...field}
                        required
                        name="supplier"
                        size="large"
                        label="Supplier"
                        InputProps={{
                          classes: { root: "select-input-styles" },
                        }}
                        fullWidth
                        error={!!errors.supplier}
                        helperText={validate.supplier?.message[errors.supplier?.type]}
                      >
                        {supplier ? (
                          supplier.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Options</MenuItem>
                        )}
                      </MDSelect>
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} lg={2}>
                <MDBox>
                  <Controller
                    control={control}
                    name="invoiceNo"
                    {...register("invoiceNo", { required: true })}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        label="Invoice No."
                        error={!!errors.invoiceNo}
                        helperText={validate.invoiceNo?.message[errors.invoiceNo?.type]}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} lg={2}>
                <MDBox>
                  <Controller
                    control={control}
                    name="date"
                    {...register("date", { required: true })}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        label="Date"
                        type="date"
                        error={!!errors.date}
                        helperText={validate.date?.message[errors.date?.type]}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={2}>
                <MDBox>
                  <MDButton variant="gradient" onClick={handleProductModalOpen} color="info">
                    <Icon>add</Icon>
                    Add Item
                  </MDButton>
                  <MDModal
                    onClose={handleProductModalClose}
                    open={openProductModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <ProductFormModal onAddItem={handleAddItem} onClose={handleProductModalClose} />
                  </MDModal>
                </MDBox>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} mb={3} justify="center">
              <Grid item xs={12}>
                <Grid container px={2}>
                  <Grid item xs={4} px={2}>
                    <MDTypography fontWeight="light" variant="h6">
                      Product Name
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} px={2}>
                    <MDTypography fontWeight="light" variant="h6">
                      Total Cost Price
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} px={2}>
                    <MDTypography fontWeight="light" variant="h6">
                      Qty
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} px={2}>
                    <MDTypography fontWeight="light" variant="h6">
                      Unit Cost Price
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {isDirty && errors.items && (
                  <MDTypography xs={12} variant="caption" color="error">
                    {validate.items.message[errors.items?.root?.type]}
                  </MDTypography>
                )}
              </Grid>
              {fields.reverse().map((item, index) => (
                <Grid item xs={12} key={`${item.itemName}_${item.id}`}>
                  <Grid container px={2}>
                    <Grid item xs={4} px={2}>
                      <MDInput
                        fullWidth
                        disabled
                        defaultValue={item.itemName}
                        name={`items[${index}].itemName`}
                        {...register(`items[${index}].itemName`, { required: true })}
                      />
                    </Grid>
                    <Grid item xs={2} px={2}>
                      <MDInput
                        defaultValue={item.costPrice}
                        name={`items[${index}].costPrice`}
                        {...register(`items[${index}].costPrice`, { required: true })}
                        onChange={(event) => handleCostPriceChange(index, event)}
                        error={!!errors.items?.[index]?.costPrice}
                        helperText={
                          validate.items.costPrice.message[errors.items?.[index]?.costPrice?.type]
                        }
                      />
                    </Grid>
                    <Grid item xs={2} px={2}>
                      <MDInput
                        defaultValue={item.qty}
                        name={`items[${index}].qty`}
                        {...register(`items[${index}].qty`, { required: true })}
                        onChange={(event) => handleQtyChange(index, event)}
                        error={!!errors.items?.[index]?.qty}
                        helperText={validate.items.qty.message[errors.items?.[index]?.qty?.type]}
                      />
                    </Grid>
                    <Grid item xs={2} px={2}>
                      <MDInput
                        name={`items[${index}].unitCostPrice`}
                        {...register(`items[${index}].unitCostPrice`, { required: true })}
                        error={!!errors.items?.[index]?.unitCostPrice}
                        helperText={
                          validate.items.unitCostPrice.message[
                            errors.items?.[index]?.unitCostPrice?.type
                          ]
                        }
                      />
                    </Grid>
                    <Grid item xs={2} px={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        iconOnly
                        onClick={() => setRemoveIndex(index)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

export default NewFormDialog;

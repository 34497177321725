import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";

// Components
import Form from "layouts/product_setup/product/components/Form";

// Redux
import { toggleDialog, filter } from "redux/product";

function Toolbar() {
  const dispatch = useDispatch();

  // Selector
  const categorySelector = useSelector((state) => state.firestore.ordered.category);
  const productUnitSelector = useSelector((state) => state.firestore.ordered.product_unit);

  // State
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);
  const [unit, setUnit] = useState("");

  // Functions
  const handleDialogToggle = () => {
    dispatch(toggleDialog());
  };

  const handleFilter = () => {
    dispatch(
      filter({
        name,
        category,
        unit,
      })
    );
  };

  const resetFilter = () => {
    setName("");
    setCategory("");
    setUnit("");
    dispatch(
      filter({
        name: null,
        category: null,
        unit: null,
      })
    );
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <MDButton variant="gradient" color="info" onClick={handleDialogToggle}>
                New Product
              </MDButton>
              <Form />
            </Grid>
            <Grid item>
              <MDButton variant="outlined" color="dark" onClick={() => setOpen(!open)}>
                Filter&nbsp;<Icon>{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
              </MDButton>
            </Grid>
          </Grid>
          {open && (
            <Grid container mt={2}>
              <Grid item lg={12}>
                <Card>
                  <MDBox py={2} px={2}>
                    <Grid container spacing={1}>
                      <Grid item lg={4}>
                        <MDInput
                          fullWidth
                          label="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <MDSelect
                          name="category"
                          size="large"
                          label="Category"
                          value={category}
                          InputProps={{
                            classes: { root: "select-input-styles" },
                          }}
                          fullWidth
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          {categorySelector ? (
                            categorySelector.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>No Options</MenuItem>
                          )}
                        </MDSelect>
                      </Grid>
                      <Grid item lg={2}>
                        <MDSelect
                          name="unit"
                          size="large"
                          label="Unit"
                          value={unit}
                          InputProps={{
                            classes: { root: "select-input-styles" },
                          }}
                          fullWidth
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          {productUnitSelector ? (
                            productUnitSelector.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>No Options</MenuItem>
                          )}
                        </MDSelect>
                      </Grid>
                      <Grid item lg={6}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <MDButton variant="outlined" color="dark" onClick={resetFilter}>
                              Reset
                            </MDButton>
                          </Grid>
                          <Grid item>
                            <MDButton variant="outlined" color="dark" onClick={handleFilter}>
                              Search
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Toolbar;

const Validation = {
  machine: {
    rules: { required: true },
    message: { required: "Machine is required" },
  },
  date: {
    rules: { required: true },
    message: { required: "Date is required" },
  },
  time: {
    rules: { required: true },
    message: { required: "Time is required" },
  },
  bank_in_date: {
    rules: { required: false },
  },
  // sku: {
  //   rules: { required: true },
  //   message: { required: "SKU is required" },
  // },
  // category: {
  //   rules: { required: true },
  //   message: { required: "Category is required" },
  // },
  // uom: {
  //   rules: { required: true },
  //   message: { required: "UOM is required" },
  // },
};

export default Validation;

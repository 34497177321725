import { useState } from "react";
import { useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Components
import Form from "layouts/finance/cash_received/components/Form";

// Redux
import { toggleDialog } from "redux/cash_received";

function Toolbar() {
  const dispatch = useDispatch();

  // State
  const [open, setOpen] = useState(false);

  // Functions
  const handleDialogToggle = () => {
    dispatch(toggleDialog());
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <MDButton variant="gradient" color="info" onClick={handleDialogToggle}>
                New Cash Received
              </MDButton>
              <Form />
            </Grid>
            <Grid item>
              <MDButton variant="outlined" color="dark" onClick={() => setOpen(!open)}>
                Filter&nbsp;<Icon>{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
              </MDButton>
            </Grid>
          </Grid>
          {open && (
            <Grid container mt={2}>
              <Grid item lg={12}>
                <Card>
                  <MDBox py={2} px={2}>
                    <Grid container>
                      <Grid item>abc</Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Toolbar;

import { useMemo } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Material Dashboard 2 React components
import MDBadge from "components/MDBadge";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// import Icon from "@mui/material/Icon";
import SignalCellular4Bar from "@mui/icons-material/SignalCellular4Bar";
import Wifi from "@mui/icons-material/Wifi";

import moment from "moment-timezone";

// Redux
// import { toggleDialog } from "redux/machine";

export default function data() {
  // const dispatch = useDispatch();

  // Selector
  const machineSelector = useSelector((state) => state.firestore.ordered.machine);

  // memo
  const machine = useMemo(() => {
    let flatResult = [];
    if (machineSelector) {
      flatResult = machineSelector.map((item) => ({
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.name}
          </MDTypography>
        ),
        // vendor: (
        //   <MDTypography variant="caption" color="text" fontWeight="medium">
        //     {item.vendor_info.name}
        //   </MDTypography>
        // ),
        connectivity: (
          <>
            {item.connectivity.type === "mobile data" && <SignalCellular4Bar />}
            {item.connectivity.type === "wifi" && <Wifi />}
          </>
        ),
        sales: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.online_info && (
              <>
                Total Sales: RM{parseFloat(item.total_order).toFixed(2)}
                <br />
                Total Profit: RM{parseFloat(item.total_profit).toFixed(2)}
              </>
            )}
          </MDTypography>
        ),
        stockLevel: (
          <>
            {Math.floor(item.available_stock_level) <= 100 &&
              Math.floor(item.available_stock_level) > 60 && (
                <MDTypography variant="caption" color="success" fontWeight="medium">
                  {Math.floor(item.available_stock_level)}%
                </MDTypography>
              )}
            {Math.floor(item.available_stock_level) <= 60 &&
              Math.floor(item.available_stock_level) > 30 && (
                <MDTypography variant="caption" color="warning" fontWeight="medium">
                  {Math.floor(item.available_stock_level)}%
                </MDTypography>
              )}
            {Math.floor(item.available_stock_level) <= 30 &&
              Math.floor(item.available_stock_level) > 0 && (
                <MDTypography variant="caption" color="error" fontWeight="medium">
                  {Math.floor(item.available_stock_level)}%
                </MDTypography>
              )}
          </>
        ),
        lackQty: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.total_lack}
          </MDTypography>
        ),
        onlineState: (
          <MDBadge
            badgeContent={item.online_info ? item.online_info.status : "offline"}
            color={item.online_info?.status === "online" ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        ),
        lastOnlineDate: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.online_info && (
              <>
                {moment(item.online_info.last_online_timestamp)
                  .tz("Asia/Kuala_Lumpur")
                  .format("YYYY-MM-DD")}
                <br />
                {moment(item.online_info.last_online_timestamp)
                  .tz("Asia/Kuala_Lumpur")
                  .format("HH:mm:ss")}
              </>
            )}
          </MDTypography>
        ),
        // action: (
        //   <>
        //     <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
        //       Manage
        //     </MDButton>
        //     <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
        //       Edit
        //     </MDButton>
        //   </>
        // ),
      }));
    }
    return flatResult;
  }, [machineSelector]);

  return {
    columns: [
      { Header: "name", accessor: "name", width: "20%", align: "left" },
      // { Header: "vendor", accessor: "vendor", width: "20%", align: "left" },
      { Header: "connectivity", accessor: "connectivity", width: "10%", align: "center" },
      { Header: "sales summary", accessor: "sales", width: "10%", align: "center" },
      { Header: "stock level", accessor: "stockLevel", width: "10%", align: "center" },
      { Header: "lack qty", accessor: "lackQty", width: "10%", align: "center" },
      { Header: "online state", accessor: "onlineState", width: "10%", align: "center" },
      { Header: "last online", accessor: "lastOnlineDate", width: "10%", align: "center" },
      // { Header: "action", accessor: "action", align: "center" },
    ],

    rows: machine,
  };
}

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import { default as Product } from "layouts/product_setup/product";
import { default as ProductCategory } from "layouts/product_setup/category";
import Profile from "layouts/profile";
import Machine from "layouts/machine";

import CashReceived from "layouts/finance/cash_received";
import OrderHistory from "layouts/order/order_history";

// import Supplier from "layouts/supplier";
import Received from "layouts/stock/received";
import StockAdjustment from "layouts/stock/adjustment";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "divider",
    key: "machine_divider",
  },
  {
    type: "title",
    title: "machine",
    key: "machine_title",
  },
  {
    type: "collapse",
    name: "Machine",
    key: "machine",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/machine",
    component: <Machine />,
  },
  {
    type: "divider",
    key: "order_divider",
  },
  {
    type: "title",
    title: "order",
    key: "order_title",
  },
  {
    type: "collapse",
    name: "Order History",
    key: "order-history",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/order-history",
    component: <OrderHistory />,
  },
  {
    type: "divider",
    key: "inventory_divider",
  },
  {
    type: "title",
    title: "inventory",
    key: "inventory_title",
  },
  // {
  //   type: "collapse",
  //   name: "Stock",
  //   key: "stock",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/stock",
  //   component: <Received />,
  // },
  {
    type: "collapse",
    name: "Stock Received",
    key: "stock-received",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/stock-received",
    component: <Received />,
  },
  {
    type: "collapse",
    name: "Stock Adjustment",
    key: "stock-adjustment",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/stock-adjustment",
    component: <StockAdjustment />,
  },
  // {
  //   type: "collapse",
  //   name: "Stock Replenishment",
  //   key: "stock-replenishment",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/stock-replenishment",
  //   component: <Received />,
  // },
  {
    type: "divider",
    key: "finance_divider",
  },
  {
    type: "title",
    title: "finance",
    key: "finance_title",
  },
  {
    type: "collapse",
    name: "Cash Received",
    key: "cash-received",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/cash-received",
    component: <CashReceived />,
  },
  {
    type: "divider",
    key: "product_setup_divider",
  },
  {
    type: "title",
    title: "product setup",
    key: "product_setup_title",
  },
  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <Icon fontSize="small">view_list</Icon>,
    route: "/product",
    component: <Product />,
  },
  {
    type: "collapse",
    name: "Product Units",
    key: "product-units",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/product-units",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Category",
    key: "categories",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/categories",
    component: <ProductCategory />,
  },
  {
    type: "divider",
    title: "supplier setup",
    key: "supplier_setup_divider",
  },
  {
    type: "title",
    title: "supplier setup",
    key: "supplier_setup_title",
  },
  {
    type: "collapse",
    name: "Supplier",
    key: "suppliers",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/suppliers",
    component: <Profile />,
  },
  // {
  //   type: "divider",
  //   key: "divider",
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Out",
  //   key: "sign-out",
  //   icon: <Icon fontSize="small">logout</Icon>,
  //   route: "/sign-out",
  //   component: () => alert("abc"),
  // },
  // {
  //   type: "collapse",
  //   name: "Products",
  //   key: "product",
  //   icon: <Icon fontSize="small">view_list</Icon>,
  //   route: "/products",
  //   // component: <Received />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing 1",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;

import { useMemo } from "react";
import { useSelector } from "react-redux";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// firebase
import { useFirestoreConnect } from "react-redux-firebase";

export default function data() {
  // firestore
  useFirestoreConnect([
    {
      collection: "category",
      orderBy: ["name", "asc"],
    },
  ]);

  const categorySelector = useSelector((state) => state.firestore.ordered.category);

  // memo
  const category = useMemo(() => {
    let flatResult = [];
    if (categorySelector) {
      flatResult = categorySelector.map((item) => ({
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.name}
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            status
          </MDTypography>
        ),
        createdBy: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            created by
          </MDTypography>
        ),
        action: (
          <MDButton variant="text" color="info">
            Edit
          </MDButton>
        ),
      }));
    }
    return flatResult;
  }, [categorySelector]);

  return {
    columns: [
      { Header: "name", accessor: "name", width: "40%", align: "left" },
      { Header: "status", accessor: "status", width: "20%", align: "left" },
      { Header: "created by", accessor: "createdBy", width: "20%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: category,
  };
}

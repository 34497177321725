import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material Dashboard 2 React components
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// firebase
import { useFirestoreConnect } from "react-redux-firebase";

// Redux
import { toggleDialog } from "redux/stock_received";

export default function data() {
  // firestore
  useFirestoreConnect([
    {
      collection: "stock_received",
      orderBy: ["invoice_date", "desc"],
    },
  ]);

  const dispatch = useDispatch();

  const stockReceivedSelector = useSelector((state) => state.firestore.ordered.stock_received);

  // memo
  const stockReceived = useMemo(() => {
    let flatResult = [];
    if (stockReceivedSelector) {
      flatResult = stockReceivedSelector.map((item) => ({
        supplier: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.supplier.name}
          </MDTypography>
        ),
        invoiceNo: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.invoice_no}
          </MDTypography>
        ),
        invoiceDate: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.invoice_date}
          </MDTypography>
        ),
        totalItem: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.items.length}
          </MDTypography>
        ),
        totalAmount: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {parseFloat(item.total_amount).toFixed(2)}
          </MDTypography>
        ),
        status: (
          <MDBadge
            badgeContent={item.status}
            color={item.status === "draft" ? "warning" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        createdBy: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.created_info.display_name}
          </MDTypography>
        ),
        action: (
          <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
            Edit
          </MDButton>
        ),
      }));
    }
    return flatResult;
  }, [stockReceivedSelector]);

  return {
    columns: [
      { Header: "supplier", accessor: "supplier", width: "20%", align: "left" },
      { Header: "invoice no.", accessor: "invoiceNo", width: "20%", align: "left" },
      { Header: "invoice date", accessor: "invoiceDate", width: "10%", align: "center" },
      { Header: "total items", accessor: "totalItem", width: "10%", align: "center" },
      { Header: "total Amount", accessor: "totalAmount", width: "10%", align: "center" },
      { Header: "status", accessor: "status", width: "10%", align: "center" },
      { Header: "Created By", accessor: "createdBy", width: "10%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: stockReceived,
  };
}

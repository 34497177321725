const ProductValidate = {
  name: {
    rules: { required: true },
    message: { required: "Name is required" },
  },
  sku: {
    rules: { required: true },
    message: { required: "SKU is required" },
  },
  category: {
    rules: { required: true },
    message: { required: "Category is required" },
  },
  uom: {
    rules: { required: true },
    message: { required: "UOM is required" },
  },
};

export default ProductValidate;

import { createFirestoreInstance } from "redux-firestore";

import store from "redux/store";

import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

firebase.firestore();
firebase.storage();

const auth = getAuth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const signInWithGoogle = () => firebase.auth().signInWithPopup(provider);
const signInWithEmailAndPassword = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);
const signOut = () => firebase.auth().signOut();

const rrfProps = {
  firebase,
  config: {
    userProfile: "users",
    useFirestoreForProfile: true,
    profileFactory: (userData, profileData) => {
      const providerData =
        profileData.providerData && profileData.providerData[0]
          ? profileData.providerData[0]
          : null;

      const now = new Date();
      let firstName = "";
      let lastName = "";
      let email = "";

      if (!providerData) {
        firstName = profileData.first_name;
        lastName = profileData.last_name;
        email = userData.user.email;
      } else {
        switch (providerData.providerId) {
          case "firebase":
            firstName = providerData.displayName;
            email = providerData.email;
            break;
          default:
            break;
        }
      }

      return {
        email,
        first_name: firstName,
        last_name: lastName,
        created_at: now,
        type: "user",
      };
    },
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export {
  auth,
  rrfProps,
  onAuthStateChanged,
  signInWithGoogle,
  signInWithEmailAndPassword,
  signOut,
};

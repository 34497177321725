import { createSlice } from "@reduxjs/toolkit";

// Data
// import productTableData from "layouts/product_setup/data/productTableData";

// const { columns, rows } = productTableData();

export const productSlice = createSlice({
  name: "product",
  initialState: {
    dialog_open: false,
    doc_id: null,
    filter_key: {
      name: null,
      category: null,
      unit: null,
    },
    // data: {
    //   columns: productTableData().columns,
    //   rows,
    // },
  },
  reducers: {
    toggleDialog: (state, action) => ({
      ...state,
      dialog_open: !state.dialog_open,
      doc_id: action.payload,
    }),
    filter: (state, action) => ({
      ...state,
      filter_key: {
        name: action.payload.name,
        category: action.payload.category,
        unit: action.payload.unit,
      },
    }),
  },
});

export const { toggleDialog, filter } = productSlice.actions;

export default productSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const cashReceivedSlice = createSlice({
  name: "cashReceived",
  initialState: {
    dialog_open: false,
    doc_id: null,
    coinAmount: 0,
  },
  reducers: {
    toggleDialog: (state, action) => ({
      ...state,
      dialog_open: !state.dialog_open,
      doc_id: action.payload,
    }),
  },
});

export const { toggleDialog } = cashReceivedSlice.actions;

export default cashReceivedSlice.reducer;

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material Dashboard 2 React components
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Redux
import { toggleDialog } from "redux/product";

export default function productTableData() {
  const dispatch = useDispatch();

  // Selector
  const productSelector = useSelector((state) => state.firestore.ordered.product);
  const filterSelector = useSelector((state) => state.product.filter_key);

  // State
  const [product, setProduct] = useState([]);

  // Effect
  useEffect(() => {
    if (productSelector) {
      let data = productSelector;

      if (filterSelector) {
        console.log(filterSelector);
        if (filterSelector.name !== null && filterSelector.name !== "") {
          data = productSelector.filter((e) =>
            e.name.toLowerCase().includes(filterSelector.name.toLowerCase())
          );
        }

        if (filterSelector.category !== null && filterSelector.category !== "") {
          data = data.filter((e) => e.category.doc_id === filterSelector.category);
        }

        if (filterSelector.unit !== null && filterSelector.unit !== "") {
          data = data.filter((e) => e.uom.doc_id === filterSelector.unit);
        }
      }

      const products = data.map((item) => ({
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.name}
          </MDTypography>
        ),
        sku: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.sku ? item.sku : ""}
          </MDTypography>
        ),
        category: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.category ? item.category.name : ""}
          </MDTypography>
        ),
        uom: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.uom?.name}
          </MDTypography>
        ),
        status: (
          <MDBadge
            badgeContent={item.status ? item.status : ""}
            color="success"
            variant="gradient"
            size="sm"
          />
        ),
        createdBy: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.created_info?.display_name}
          </MDTypography>
        ),
        action: (
          <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
            Edit
          </MDButton>
        ),
      }));

      setProduct(products);
    }
  }, [productSelector, filterSelector, setProduct]);

  // memo
  // const product = useMemo(() => {
  //   let flatResult = [];
  //   if (productSelector) {
  //     flatResult = productSelector.map((item) => ({
  //       name: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.name}
  //         </MDTypography>
  //       ),
  //       sku: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.sku ? item.sku : ""}
  //         </MDTypography>
  //       ),
  //       category: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.category ? item.category.name : ""}
  //         </MDTypography>
  //       ),
  //       uom: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.uom?.name}
  //         </MDTypography>
  //       ),
  //       status: (
  //         <MDBadge
  //           badgeContent={item.status ? item.status : ""}
  //           color="success"
  //           variant="gradient"
  //           size="sm"
  //         />
  //       ),
  //       createdBy: (
  //         <MDTypography variant="caption" color="text" fontWeight="medium">
  //           {item.created_info?.display_name}
  //         </MDTypography>
  //       ),
  //       action: (
  //         <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
  //           Edit
  //         </MDButton>
  //       ),
  //     }));
  //   }
  //   return flatResult;
  // }, [productSelector]);

  return {
    columns: [
      { Header: "name", accessor: "name", width: "40%", align: "left" },
      { Header: "sku", accessor: "sku", width: "10%", align: "left" },
      { Header: "category", accessor: "category", width: "10%", align: "left" },
      { Header: "uom", accessor: "uom", width: "10%", align: "left" },
      { Header: "status", accessor: "status", width: "10%", align: "left" },
      { Header: "created by", accessor: "createdBy", width: "20%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: product,
  };
}

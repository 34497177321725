import { createSlice } from "@reduxjs/toolkit";

export const stockReceivedSlice = createSlice({
  name: "stockReceived",
  initialState: {
    dialog_open: false,
    doc_id: null,
  },
  reducers: {
    toggleDialog: (state, action) => ({
      ...state,
      dialog_open: !state.dialog_open,
      doc_id: action.payload,
    }),
  },
});

export const { toggleDialog } = stockReceivedSlice.actions;

export default stockReceivedSlice.reducer;

import { useMemo } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Material Dashboard 2 React components
// import MDBadge from "components/MDBadge";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// import Icon from "@mui/material/Icon";
// import SignalCellular4Bar from "@mui/icons-material/SignalCellular4Bar";
// import Wifi from "@mui/icons-material/Wifi";

// import moment from "moment-timezone";

// Redux
// import { toggleDialog } from "redux/machine";

export default function data() {
  // const dispatch = useDispatch();

  // Selector
  const orderSelector = useSelector((state) => state.firestore.ordered.order);

  // memo
  const order = useMemo(() => {
    let flatResult = [];
    if (orderSelector) {
      flatResult = orderSelector.map((item) => ({
        machineName: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.machine_info.name}
          </MDTypography>
        ),
        orderNo: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.order_no}
          </MDTypography>
        ),
        amount: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            RM{parseFloat(item.total_amount).toFixed(2)}
          </MDTypography>
        ),
        orderDate: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {new Date(item.order_date.seconds * 1000).toLocaleDateString()}
            <br />
            {new Date(item.order_date.seconds * 1000).toLocaleTimeString("en-US")}
          </MDTypography>
        ),
        paymentMethod: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.payment_method}
          </MDTypography>
        ),
        // vendor: (
        //   <MDTypography variant="caption" color="text" fontWeight="medium">
        //     {item.vendor_info.name}
        //   </MDTypography>
        // ),
        // action: (
        //   <>
        //     <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
        //       Manage
        //     </MDButton>
        //     <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
        //       Edit
        //     </MDButton>
        //   </>
        // ),
      }));
    }
    return flatResult;
  }, [orderSelector]);

  return {
    columns: [
      { Header: "machine name", accessor: "machineName", width: "20%", align: "left" },
      { Header: "order no.", accessor: "orderNo", width: "10%", align: "left" },
      { Header: "amount", accessor: "amount", width: "10%", align: "left" },
      { Header: "order date", accessor: "orderDate", width: "10%", align: "center" },
      { Header: "payment method", accessor: "paymentMethod", width: "10%", align: "left" },
      // { Header: "action", accessor: "action", align: "center" },
    ],

    rows: order,
  };
}

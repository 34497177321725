// import { useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAutocomplete from "components/MDAutocomplete";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function makeId() {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 6) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function ProductFormModal({ onAddItem, onClose }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur" });

  const product = useSelector((state) => state.firestore.ordered.product);

  const validate = {
    item: {
      rules: {
        required: true,
      },
      message: {
        required: "Item is required",
      },
    },
    costPrice: {
      rules: {
        required: true,
        pattern: {
          value: /^(0|[1-9]\d*)(\.\d+)?$/,
        },
      },
      message: {
        required: "Cost Price is required",
        pattern: "Only numbers are allowed",
      },
    },
    qty: {
      rules: {
        required: true,
        pattern: {
          value: /^(0|[1-9]\d*)(\.\d+)?$/,
        },
      },
      message: {
        required: "Qty is required",
        pattern: "Only numbers are allowed",
      },
    },
  };

  const onSubmit = (value) => {
    let newValue = value;
    newValue = {
      ...newValue,
      uid: makeId(),
    };
    onAddItem(newValue);
    onClose();
  };

  return (
    <MDBox
      style={style}
      sx={{ width: "50%" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Add Item
              </MDTypography>
            </MDBox>
            <Grid container spacing={2} px={2} pt={3}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="item"
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <MDAutocomplete
                          noOptionsText="Loading..."
                          onChange={(_, item) => {
                            onChange(item);
                          }}
                          defaultValue={null}
                          options={
                            product
                              ? product.map((item) => ({
                                  id: item.id,
                                  name: item.name,
                                  label: item.name,
                                }))
                              : []
                          }
                          getOptionLabel={(item) => (item.label ? item.label : "")}
                          isOptionEqualToValue={(option, val) => option && option.id === val.id}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label="item"
                              error={!!errors.item}
                              helperText={validate.item?.message[errors.item?.type]}
                              required
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={2} pt={1}>
                    <MDButton variant="gradient" color="info" iconOnly>
                      <Icon>add</Icon>
                    </MDButton>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <MDInput
                      name="costPrice"
                      label="Cost Price"
                      type="number"
                      {...register("costPrice", validate.costPrice.rules)}
                      error={!!errors.costPrice}
                      helperText={validate.costPrice?.message[errors.costPrice?.type]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MDInput
                      name="qty"
                      label="Qty"
                      type="number"
                      {...register("qty", validate.qty.rules)}
                      error={!!errors.qty}
                      helperText={validate.qty?.message[errors.qty?.type]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={6} px={2} py={3}>
              <Grid item sx={{ marginLeft: "auto" }}>
                <MDButton type="submit" color="info" onClick={handleSubmit(onSubmit)}>
                  Add
                </MDButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

ProductFormModal.propTypes = {
  onAddItem: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProductFormModal;

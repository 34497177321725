import { configureStore, combineReducers } from "@reduxjs/toolkit";

// Firebase
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import cashReceivedReducer from "./cash_received";
import userReducer from "./user";
import stockReceivedReducer from "./stock_received";
import machineReducer from "./machine";
import productReducer from "./product";

const reducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  cash_received: cashReceivedReducer,
  user: userReducer,
  stock_received: stockReceivedReducer,
  machine: machineReducer,
  product: productReducer,
});

const initialState = {};

const store = configureStore({
  reducer,
  initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

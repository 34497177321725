import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDAutocompleteRoot from "components/MDAutocomplete/MDAutocompleteRoot";

const MDInput = forwardRef(({ error, ...rest }, ref) => (
  <MDAutocompleteRoot {...rest} ref={ref} ownerState={{ error }} />
));

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
};

export default MDInput;

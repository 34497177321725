import { useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Components
import Form from "layouts/stock/received/components/Form";

// Redux
import { toggleDialog } from "redux/stock_received";

function Toolbar() {
  const dispatch = useDispatch();

  // Functions
  const handleDialogToggle = () => {
    dispatch(toggleDialog());
  };

  return (
    <MDBox>
      <Grid container spacing={6}>
        <Grid item xs={12} px={2} mt={1}>
          <MDButton variant="gradient" type="submit" color="info" onClick={handleDialogToggle}>
            New Stock Received
          </MDButton>
          <Form />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Toolbar;

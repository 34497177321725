import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material Dashboard 2 React components
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// firebase
import { useFirestoreConnect } from "react-redux-firebase";

// Redux
import { toggleDialog } from "redux/cash_received";

export default function data() {
  // firestore
  useFirestoreConnect([
    {
      collection: "cash_received",
      orderBy: ["received_date", "desc"],
    },
  ]);

  const dispatch = useDispatch();

  const cashReceivedSelector = useSelector((state) => state.firestore.ordered.cash_received);

  // memo
  const cashReceived = useMemo(() => {
    let flatResult = [];
    if (cashReceivedSelector) {
      flatResult = cashReceivedSelector.map((item) => ({
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.machine.name}
          </MDTypography>
        ),
        receivedAt: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <>
              {new Date(item.received_date.seconds * 1000).toLocaleDateString()}
              <br />
              {new Date(item.received_date.seconds * 1000).toLocaleTimeString()}
            </>
          </MDTypography>
        ),
        bankInAt: item.bank_in_date ? (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {new Date(item.bank_in_date.seconds * 1000).toLocaleDateString()}
          </MDTypography>
        ) : (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -
          </MDTypography>
        ),
        totalAmount: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            RM{item.total.toFixed(2)}
          </MDTypography>
        ),
        status: (
          <MDBadge
            badgeContent={item.status}
            color={item.status === "pending" ? "warning" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        createdBy: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.created_info?.display_name}
          </MDTypography>
        ),
        action: (
          <MDButton variant="text" color="info" onClick={() => dispatch(toggleDialog(item.id))}>
            Edit
          </MDButton>
        ),
      }));
    }
    return flatResult;
  }, [cashReceivedSelector]);

  return {
    columns: [
      { Header: "machine name", accessor: "name", width: "25%", align: "left" },
      { Header: "received at", accessor: "receivedAt", width: "10%", align: "center" },
      { Header: "bank in at", accessor: "bankInAt", width: "10%", align: "center" },
      { Header: "total amount", accessor: "totalAmount", width: "10%", align: "center" },
      { Header: "status", accessor: "status", width: "10%", align: "center" },
      { Header: "created by", accessor: "createdBy", width: "10%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: cashReceived,
  };
}
